import { Box, IconButton, Typography, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import React, { useContext } from "react";
import ConfigurationContext from "../../../context/Configuration";
import useStyles from "./styles";

function CartItem(props) {
  const classes = useStyles();
  const theme = useTheme();
  const configuration = useContext(ConfigurationContext);
  let optionTitles = props?.optionTitles;
  let ingredientTitles = props?.ingredientTitles;
  if (/menu/i.test(props?.variationTitle)) {
    if (!optionTitles?.find((option) => /frites/i.test(option.title))) {
      optionTitles.unshift(
        {
          title: `${props.quantity} x ( ${
            props?.variationPrice
          } ${optionTitles.reduce(
            (acc, option) => (option.price ? acc + " + " + option.price : acc),
            ""
          )} )`,
          price: props.dealPrice,
          subtotal: true,
        },
        {
          title: "Frites",
          price: 0,
        }
      );
    }
  } else if (
    !optionTitles?.find((option) => {
      // console.log("option.title >>>. ", option.title);
      return new RegExp(`${props.quantity} x`, "i").test(option.title);
    })
  ) {
    optionTitles.pop();
    optionTitles.push({
      title: `${props.quantity} x ( ${
        props?.variationPrice
      } ${optionTitles.reduce(
        (acc, option) => (option.price ? acc + " + " + option.price : acc),
        ""
      )} )`,
      price: props.dealPrice,
      subtotal: true,
    });
  }

  return (
    <Box
      style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <IconButton
          size="small"
          onClick={(e) => {
            e.preventDefault();
            props.removeQuantity();
          }}
          style={{ background: theme.palette.primary.main }}
        >
          <RemoveIcon
            fontSize="small"
            style={{ color: theme.palette.common.black }}
          />
        </IconButton>
        <Typography
          style={{
            ...theme.typography.caption,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.black,
            padding: "5px 10px",
            borderRadius: 5,
            border: "1px solid theme.palette.common.black",
            margin: "0 8px",
          }}
        >
          {props.quantity ? props.quantity : 0}
        </Typography>
        <IconButton
          size="small"
          onClick={(e) => {
            e.preventDefault();
            props.addQuantity();
          }}
          style={{ background: theme.palette.primary.main }}
        >
          <AddIcon
            fontSize="small"
            color="primary"
            style={{ color: theme.palette.common.black }}
          />
        </IconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        ml={1}
        justifyContent="flex-end"
      >
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          ml={1}
          justifyContent="flex-end"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            style={{
              flexGrow: 1,
            }}
          >
            <Box
              style={{
                flexGrow: 1,
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-end"
                style={{
                  flexGrow: 1,
                }}
              >
                <Typography className={classes.itemTextStyle}>
                  {props.foodTitle
                    ? `${props.categoryTitle} ${props.foodTitle}`
                    : ""}
                </Typography>
                <Typography
                  style={{
                    color: theme.palette.common.black,
                    marginRight: theme.spacing(2),
                    textWrap: "nowrap",
                  }}
                  className={classes.subtotalText}
                >
                  {
                    parseFloat(props.variationPrice)
                      .toFixed(2)
                      ?.replace(/\./, ` ${configuration.currencySymbol} `)
                    /* ?.replace("00", "") */
                  }
                </Typography>
              </Box>
              {/* <Typography className={classes.itemTextStyle}>
                {`${
                  props.variationTitle && props.variationTitle !== "seul"
                    ? props.variationTitle
                    : ""
                }`}
              </Typography> */}
              <Typography
                style={{
                  color: theme.palette.common.black,
                  fontSize: "0.7rem",
                  marginTop: theme.spacing(0.5),
                }}
                className={classes.itemTextStyle}
              >
                {props.variationTitle && props.variationTitle !== "seul"
                  ? `${props.variationTitle}`
                  : ""}
              </Typography>
              {ingredientTitles?.length > 0 && (
                <Typography
                  style={{
                    color: theme.palette.common.black,
                    fontSize: "0.7rem",
                    marginTop: theme.spacing(0.5),
                  }}
                  className={classes.itemIngredientStyle}
                >
                  {`${"⛔"} ${ingredientTitles
                    .map((ingredient) => ingredient.title)
                    .join(", ")}`}
                </Typography>
              )}
              {optionTitles
                ?.sort((a, b) => a.price - b.price)
                ?.map((option, index) => (
                  <Box
                    key={index}
                    display="flex"
                    flexDirection="row"
                    alignContent="space-between"
                    style={{
                      flexGrow: 1,
                      // backgroundColor: "red",
                      marginRight: theme.spacing(2),
                    }}
                  >
                    <Box
                      key={`title-option-${index}`}
                      display="flex"
                      flexGrow={1}
                      alignItems="flex-start"
                      style={{
                        width: "100%",
                        marginTop: theme.spacing(0.5),
                        // backgroundColor: "green",
                      }}
                    >
                      <Typography
                        style={{
                          color: theme.palette.common.black,
                          fontSize: "0.8rem",
                        }}
                        className={classes.optionTextStyle}
                      >
                        {option.subtotal
                          ? "➡️"
                          : option.price > 0
                          ? "🔖"
                          : "🏷️"}{" "}
                        {option.title}
                      </Typography>
                    </Box>
                    {option.price > 0 && (
                      <Box
                        key={`price-option-${index}`}
                        style={{
                          // backgroundColor: "blue",
                          textAlign: "right",
                          marginTop: theme.spacing(0.5),
                        }}
                      >
                        <Typography
                          style={{
                            color: option.subtotal
                              ? theme.palette.grey[50]
                              : theme.palette.common.black,
                            fontSize: option.subtotal ? "1rem" : "0.9rem",
                            textWrap: "nowrap",
                          }}
                          className={classes.optionTextStyle}
                        >
                          {
                            parseFloat(option.price)
                              .toFixed(2)
                              ?.replace(
                                /\./,
                                ` ${configuration.currencySymbol} `
                              )
                            /* ?.replace("00", "") */
                          }
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
        {/* <Box
          display="flex"
          flexGrow={1}
          ml={1}
          justifyContent="flex-end"
          style={{
            marginTop: theme.spacing(2),
          }}
        >
          <Typography
            style={{
              color: theme.palette.common.black,
              marginRight: theme.spacing(2),
            }}
            className={classes.subtotalText}
          >
            {
              parseFloat(props.dealPrice)
                .toFixed(2)
                ?.replace(/\./, ` ${configuration.currencySymbol} `)
              // ?.replace("00", "")
            }
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
}
export default React.memo(CartItem);
