/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";

import Footer from "../../components/Footer/Footer";
import { Header, LoginHeader } from "../../components/Header";
import UserContext from "../../context/User";
import useStyle from "./styles";
import Analytics from "../../utils/analytics";

function PrivacyPolicy() {
  useEffect(async () => {
    await Analytics.track(Analytics.events.NAVIGATE_TO_PRIVACY_POLICY);
  }, []);
  const classes = useStyle();
  const { isLoggedIn } = useContext(UserContext);
  return (
    <Grid className={classes.root}>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <Grid container className={classes.mainContainer}>
        <Box className={classes.imageContainer}>
          <Typography
            variant="h4"
            color="textPrimary"
            align="center"
            className={classes.title}
          >
            Politique de Confidentialité - Applicable aux Trois Applications
            Dernière mise à jour : 16-11-2024
          </Typography>
        </Box>
        <Grid container item xs={12} className={classes.mainContainer}>
          <Grid item xs={1} md={1} />
          <Grid container item xs={10} sm={10} md={9}>
            <Typography variant="subtitle1" className={classes.boldText}>
              1. Introduction Cette politique décrit comment Tacos Locos
              collecte, utilise, et protège les informations des utilisateurs de
              ses applications.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              2. Informations Collectées Nous collectons les informations
              suivantes : Clients : Adresse email, nom, localisation GPS,
              historique des commandes. Livreurs : Nom d'utilisateur,
              localisation GPS en temps réel. Restaurants : Nom d'utilisateur,
              informations sur les commandes.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              3. Utilisation des Informations Les informations collectées sont
              utilisées pour : Traiter les commandes et les livraisons.
              Améliorer nos services. Assurer la sécurité des transactions.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              4. Partage des Données Nous ne partageons pas vos informations
              avec des tiers, sauf si nécessaire pour le traitement des
              paiements ou en réponse à des exigences légales.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              5. Sécurité des Données Nous mettons en œuvre des mesures de
              sécurité pour protéger vos données contre tout accès non autorisé.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              6. Droits des Utilisateurs Vous avez le droit d'accéder, de
              corriger ou de supprimer vos informations personnelles. Pour toute
              demande, contactez-nous à : support@tacos-locos-app.fr.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              7. Modifications de la Politique Nous nous réservons le droit de
              modifier cette politique à tout moment. Vous serez informé en cas
              de changements significatifs.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              8. Contact Pour toute question concernant cette politique,
              veuillez nous contacter à : support@tacos-locos-app.fr.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/*  <Footer /> */}
    </Grid>
  );
}

export default React.memo(PrivacyPolicy);
