/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useContext, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import { Header, LoginHeader } from "../../components/Header";
import UserContext from "../../context/User";
import Analytics from "../../utils/analytics";
import useStyle from "./styles";

function Terms() {
  useEffect(async () => {
    await Analytics.track(Analytics.events.NAVIGATE_TO_TERMS);
  }, []);
  const classes = useStyle();
  const { isLoggedIn } = useContext(UserContext);
  return (
    <Grid className={classes.root}>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <Grid container className={classes.mainContainer}>
        <Box className={classes.imageContainer}>
          <Typography
            variant="h4"
            color="textPrimary"
            align="center"
            className={classes.title}
          >
            Politique de Confidentialité - Applicable aux Trois Applications
            Dernière mise à jour : 16-11-2024
          </Typography>
        </Box>
        <Grid container item xs={12} className={classes.mainContainer}>
          <Grid item xs={1} md={1} />
          <Grid container item xs={10} sm={10} md={9}>
            <Typography variant="subtitle2" className={classes.MV3}>
              1. Termes et Conditions - Application pour les Clients Dernière
              mise à jour : 16-11-2024
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              1. Introduction Bienvenue sur notre application Tacos Locos. En
              utilisant notre service, vous acceptez les présentes conditions
              d'utilisation.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              2. Utilisation de l'Application Les utilisateurs doivent créer un
              compte via Google ou leur adresse email pour passer des commandes.
              Vous devez fournir des informations précises, notamment votre
              adresse ou votre position GPS, pour garantir une livraison
              correcte. Vous pouvez sélectionner des articles, ajouter au
              panier, et payer via les options proposées : cash, Stripe, ou
              PayPal.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              3. Paiement Les paiements sont traités via des passerelles
              sécurisées (Stripe, PayPal). Nous ne stockons aucune information
              de paiement sur nos serveurs. En cas de paiement en espèces,
              l'utilisateur doit fournir le montant exact à la livraison.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              4. Annulation et Remboursement Les commandes peuvent être annulées
              avant leur validation finale. Aucun remboursement n'est possible
              après confirmation et traitement de la commande.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              5. Responsabilités L'utilisateur est responsable des informations
              fournies (adresse, choix des articles). Nous ne sommes pas
              responsables des retards ou des erreurs de livraison dus à des
              informations incorrectes.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              6. Modifications des Termes Nous nous réservons le droit de
              modifier ces termes à tout moment. Vous serez informé en cas de
              modifications importantes.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              2. Termes et Conditions - Application pour les Livreurs Dernière
              mise à jour : 16-11-2024
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              1. Introduction Ces conditions s'appliquent à l'utilisation de
              l'application Tacos Locos livreur.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              2. Utilisation de l'Application L'accès à l'application nécessite
              un nom d'utilisateur et un mot de passe. Les livreurs doivent
              partager leur position GPS en temps réel pour recevoir et
              effectuer les livraisons. Les informations de livraison et les
              itinéraires doivent être respectés.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              3. Responsabilités Les livreurs sont responsables de la sécurité
              des commandes qu'ils transportent. Tout manquement aux
              instructions de livraison peut entraîner des sanctions.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              4. Protection des Données Les informations de localisation
              partagées par les livreurs sont utilisées uniquement pour la
              gestion des livraisons.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              5. Modifications des Termes Nous nous réservons le droit de
              modifier ces termes à tout moment.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              3. Termes et Conditions - Application pour les Restaurants
              Dernière mise à jour : 16-11-2024
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              1. Introduction Ces conditions régissent l'utilisation de
              l'application Tacos Locos restaurant.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              2. Utilisation de l'Application L'accès à l'application nécessite
              un nom d'utilisateur et un mot de passe. Les restaurants reçoivent
              les commandes via l'application et doivent confirmer les commandes
              en temps opportun.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              3. Responsabilités Les restaurants sont responsables de la
              préparation correcte des commandes et du respect des délais. En
              cas de problèmes de commande, le restaurant doit informer
              immédiatement le support.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              4. Modifications des Termes Nous nous réservons le droit de
              modifier ces termes à tout moment.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/*  <Footer /> */}
    </Grid>
  );
}

export default React.memo(Terms);
