/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  OutlinedInput,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import clsx from "clsx";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ConfigurationContext from "../../../context/Configuration";
import UserContext from "../../../context/User";
import HeadingView from "./HeadingView";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import _ from "lodash";

function VariationModal({ isVisible, initialQuantity = 1, toggleModal, data }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const configuration = useContext(ConfigurationContext);
  const extraSmall = useMediaQuery(theme.breakpoints.down("lg"));
  const [quantity, setQuantity] = useState(initialQuantity);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [maxQuantityAddons, setMaxQuantityAddons] = useState({});
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [specialInstructions, setSpecialInstructions] = useState("");
  const {
    restaurant: restaurantCart,
    setCartRestaurant,
    cart,
    addQuantity,
    updateIngredients,
    addCartItem,
  } = useContext(UserContext);

  useEffect(() => {
    setQuantity(1);
  }, [isVisible]);

  useEffect(() => {
    /* console.log(
      " data?.food.variations[0].ingredients",
      data?.food.variations[0].ingredients,
      // data?.food.variations[0].addons
      data?.food.variations[0].ingredients?.map((fi) => {
        return data?.ingredients.find((a) => a._id === fi);
      })
    ); */
    setSelectedVariation({
      ...data?.food.variations[0],
      addons: data?.food.variations[0].addons.map((fa) => {
        const addon = data?.addons.find((a) => a._id === fa);
        const addonOptions = addon.options.map((ao) => {
          return data?.options.find((o) => o._id === ao);
        });
        return {
          ...addon,
          options: addonOptions,
        };
      }),
      ingredients: data?.food.variations[0]?.ingredients?.map((fi) => {
        return data?.ingredients.find((a) => a._id === fi);
      }),
    });
    setSelectedIngredients(
      data?.food.variations[0]?.ingredients?.map((fi) => {
        return data?.ingredients.find((a) => a._id === fi);
      })
    );
    return () => {
      setSelectedAddons([]);
      setSelectedIngredients([]);
      setSelectedVariation(null);
    };
  }, [data]);

  const onAdd = useCallback(() => {
    setQuantity(quantity + 1);
  }, [quantity]);

  const onRemove = useCallback(() => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : prev));
  }, [quantity]);

  const calculatePrice = useCallback(() => {
    const variation = selectedVariation?.price;
    let addons = 0;
    selectedAddons?.forEach((addon) => {
      addons += addon.options.reduce((acc, option) => {
        /* console.log(
          " addon >>>> ",
          data?.addons.find((a) => a._id === addon._id)
        ); */
        return acc + option.price;
      }, 0);
    });
    return (variation + addons) * quantity;
  }, [data, selectedVariation, quantity]);

  const onSelectVariation = useCallback(
    (variation) => {
      setSelectedVariation({
        ...variation,
        addons: variation.addons.map((fa) => {
          const addon = data?.addons.find((a) => a._id === fa);
          const addonOptions = addon.options.map((ao) => {
            return data?.options.find((o) => o._id === ao);
          });
          return {
            ...addon,
            options: addonOptions,
          };
        }),
        ingredients: data?.food.variations[0].ingredients.map((fi) => {
          return data?.ingredients.find((a) => a._id === fi);
        }),
      });
    },
    [data]
  );

  const onSelectOption = async (addon, option) => {
    const index = selectedAddons.findIndex((ad) => ad._id === addon._id);

    // setMaxQuantityAddons({

    // });

    /* const dataAddon = data?.addons.find((a) => a._id === addon._id);
    if (
      dataAddon?.minQuantityAddons &&
      dataAddon?.maxQuantityAddons &&
      dataAddon?.maxQuantityAddons > dataAddon?.minQuantityAddons
    ) {
      console.log(
        " +++++++ addon has max > min ",
        dataAddon?.minQuantityAddons,
        dataAddon?.maxQuantityAddons
      );
    } */

    if (index > -1) {
      if (addon.quantityMinimum === 1 && addon.quantityMaximum === 1) {
        selectedAddons[index].options = [option];
      } else {
        const optionIndex = selectedAddons[index].options.findIndex(
          (opt) => opt._id === option._id
        );
        if (optionIndex > -1) {
          selectedAddons[index].options = selectedAddons[index].options.filter(
            (opt) => opt._id !== option._id
          );
        } else {
          selectedAddons[index].options.push(option);
        }
        if (!selectedAddons[index].options.length) {
          selectedAddons.splice(index, 1);
        }
      }
    } else {
      selectedAddons.push({ _id: addon._id, options: [option] });
    }
    // console.log("~~~~~~~ selectedAddons ", selectedAddons);
    setSelectedAddons([...selectedAddons]);
  };

  const onSelectIngredient = async (ingredient) => {
    const index = selectedIngredients.findIndex(
      (ing) => ing._id === ingredient._id
    );
    if (index > -1) {
      selectedIngredients.splice(index, 1);
    } else {
      selectedIngredients.push({ _id: ingredient._id });
    }
    // console.log("~~~~~~~ selectedIngredients ", selectedIngredients);
    setSelectedIngredients([...selectedIngredients]);
  };

  const validateButton = () => {
    if (!selectedVariation) return false;
    const validatedAddons = [];
    selectedVariation?.addons?.forEach((addon) => {
      const selected = selectedAddons.find((ad) => ad._id === addon._id);
      if (!selected && addon.quantityMinimum === 0) {
        validatedAddons.push(false);
      } else if (
        selected &&
        selected.options.length >= addon.quantityMinimum &&
        selected.options.length <= addon.quantityMaximum
      ) {
        validatedAddons.push(false);
      } else validatedAddons.push(true);
    });
    return validatedAddons.every((val) => val === false);
  };

  const validateOrderItem = () => {
    const validatedAddons = selectedVariation?.addons.map((addon) => {
      const selected = selectedAddons.find((ad) => ad._id === addon._id);

      // console.log(">>>> min, max addon, selected ", selected);

      if (
        (!selected && addon.quantityMinimum > 0) ||
        (selected &&
          addon.quantityMinimum > 0 &&
          addon.quantityMaximum > 0 &&
          (selected?.options?.length < addon.quantityMinimum ||
            selected?.options?.length < addon.quantityMaximum ||
            selected?.options?.length > addon.quantityMaximum))
      ) {
        addon.error = true;
      } else {
        addon.error = false;
      }
      return addon;
    });
    setSelectedVariation({
      ...selectedVariation,
      ingredients: selectedIngredients,
      addons: validatedAddons,
    });
    return validatedAddons.every((addon) => addon.error === false);
  };

  const onPressAddToCart = async () => {
    if (validateOrderItem()) {
      if (!restaurantCart || data?.restaurant === restaurantCart) {
        await addToCart(quantity, data?.restaurant !== restaurantCart);
      }
    }
  };

  const addToCart = async (quantity, clearFlag) => {
    const addons = selectedAddons?.map((addon) => ({
      ...addon,
      options: addon.options.map(({ _id }) => ({
        _id,
      })),
    }));

    /* console.log(
      "data?.food.variations[0].ingredients ",
      data?.food.variations[0].ingredients
    );
    console.log(
      "selectedIngredients ",
      selectedIngredients.map((ing) => ing._id)
    ); */
    let ingredients = _.difference(
      data?.food.variations[0].ingredients,
      selectedIngredients?.map((ing) => ing._id)
    );
    ingredients = data?.ingredients
      ?.map((ing) => {
        if (ingredients.includes(ing._id)) {
          return { _id: ing._id /* , title: ing.title */ };
        }
      })
      ?.filter((ing) => ing); /* console.log(
      `?????????????? _.difference(
      data?.food.variations[0].ingredients,
      selectedIngredients.map((ing) => ing._id)) ?????? `,
      _.difference(
        data?.food.variations[0].ingredients,
        selectedIngredients.map((ing) => ing._id)
      )
    ); */
    // console.log(
    //   "?????????????? selectedIngredients ?????? ",
    //   selectedIngredients
    // );
    // console.log("?????????????? ingredients ?????? ", ingredients);

    // return;

    const cartItem = clearFlag
      ? null
      : cart.find((cartItem) => {
          if (
            cartItem._id === data?.food._id &&
            cartItem.variation._id === selectedVariation?._id
          ) {
            if (cartItem.addons.length === addons.length) {
              if (addons.length === 0) return true;
              const addonsResult = addons.every((newAddon) => {
                const cartAddon = cartItem.addons.find(
                  (ad) => ad._id === newAddon._id
                );

                if (!cartAddon) return false;
                const optionsResult = newAddon.options.every((newOption) => {
                  const cartOption = cartAddon.options.find(
                    (op) => op._id === newOption._id
                  );

                  if (!cartOption) return false;
                  return true;
                });

                return optionsResult;
              });

              return addonsResult;
            }
          }
          return false;
        });

    if (!cartItem) {
      await setCartRestaurant(data?.restaurant);
      await addCartItem({
        _id: data?.food?._id,
        variation: selectedVariation?._id,
        quantity,
        ingredients,
        addons,
        clearFlag,
        specialInstructions,
      });
    } else {
      await addQuantity(cartItem.key, quantity);
      await updateIngredients(cartItem.key, ingredients);
    }
    toggleModal();
  };

  const radioORcheckboxes = useCallback(
    (addon) => {
      // console.log(" addon?.options ", addon?.options);
      if (addon.quantityMinimum === 1 && addon.quantityMaximum === 1) {
        return (
          <RadioGroup>
            {addon?.options
              ?.sort((f, s) => {
                // First, sort by price (descending)
                if (s.price !== f.price) {
                  return s.price - f.price;
                }
                // If prices are equal, sort by title (alphabetically)
                return f.title.localeCompare(s.title);
              })
              ?.map((option, index) => (
                <Box
                  key={`OPTIONS_${option._id}`}
                  display="flex"
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    value={option._id}
                    onChange={(e) => {
                      console.log(
                        " RAAADIO event.target.checked ",
                        e.target.checked
                      );
                      onSelectOption(addon, option);
                    }}
                    control={<Radio color="primary" />}
                    label={
                      <Typography
                        style={{ color: theme.palette.text.primary }}
                        className={classes.priceTitle}
                      >
                        {option.title}
                      </Typography>
                    }
                  />
                  {option.price > 0 && (
                    <Typography className={classes.priceAddon}>
                      {
                        parseFloat(option.price)
                          .toFixed(2)
                          ?.replace(/\./, ` ${configuration.currencySymbol} `)
                        /* ?.replace("00", "") */
                      }
                    </Typography>
                  )}
                </Box>
              ))}
          </RadioGroup>
        );
      } else {
        return addon?.options
          .sort((f, s) => {
            // First, sort by price (descending)
            if (s.price !== f.price) {
              return s.price - f.price;
            }
            // If prices are equal, sort by title (alphabetically)
            return f.title.localeCompare(s.title);
          })
          ?.map((option, index) => (
            <Box
              key={`OPTIONS_${option._id}_${index}`}
              display="flex"
              justifyContent="space-between"
            >
              <FormControlLabel
                control={<Checkbox color="primary" />}
                // sx={{ display: "none" }}
                onChange={(e) => {
                  console.log(
                    " CHHECKBOX event.target.checked ",
                    e.target.checked
                  );
                  onSelectOption(addon, option);
                }}
                name={option._id}
                label={
                  <Typography
                    style={{
                      color:
                        /sauce fromagère/i.test(option.title) &&
                        option.price > 0
                          ? theme.palette.text.secondary
                          : theme.palette.text.primary,
                    }}
                    className={classes.priceTitle}
                  >
                    {option.title}
                  </Typography>
                }
              />
              {option.price > 0 && (
                <Typography
                  // sx={{ display: "none" }}
                  className={classes.priceAddon}
                >
                  {
                    parseFloat(option.price)
                      .toFixed(2)
                      ?.replace(/\./, ` ${configuration.currencySymbol} `)
                    /* ?.replace("00", "") */
                  }
                </Typography>
              )}
            </Box>
          ));
      }
    },
    [selectedVariation]
  );

  const ingredientCheckboxes = useCallback(
    (ingredient) => {
      /* console.log(
        ">>>>>>>>> ingredientCheckboxes >>>>>>>>> selectedIngredients ",
        selectedIngredients
      ); */
      return (
        <Box
          key={`INGREDIENTS_${ingredient._id}`}
          display="flex"
          justifyContent="space-between"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  !selectedIngredients.find((ing) => ing._id === ingredient._id)
                    ? false
                    : true
                }
                color="primary"
              />
            }
            onChange={(e) => {
              console.log(
                " CHHECKBOX ingredient event.target.checked ",
                e.target.checked
              );
              onSelectIngredient(ingredient);
            }}
            name={ingredient._id}
            label={
              <Typography
                style={{ color: theme.palette.text.primary }}
                className={classes.priceTitle}
              >
                {ingredient.title}
              </Typography>
            }
          />
        </Box>
      );
    },
    [selectedVariation, selectedIngredients]
  );

  return (
    <>
      <Dialog
        fullScreen={extraSmall}
        onClose={toggleModal}
        open={isVisible}
        fullWidth={true}
        maxWidth="lg"
        pt={theme.spacing(0.5)}
        className={classes.root}
      >
        <Box className={classes.outerContainer}>
          <Box
            className={classes.imageContainer}
            style={{
              backgroundImage: `url('${data?.image ?? ""}')`,
            }}
          >
            {/* <Typography variant="h4" color="white" style={{ fontWeight: 600 }}>
              {t("customize")}
            </Typography> */}
            <Button onClick={toggleModal} className={classes.closeContainer}>
              <CloseIcon style={{ color: "white" }} />
            </Button>
          </Box>
          <Paper className={classes.innerContainer}>
            <Box className={classes.lowerContainer}>
              <DialogTitle>
                <Box display="flex" justifyContent="space-between">
                  <Typography className={classes.itemTitle}>
                    {data?.food?.category} {data?.food?.title ?? ""}
                  </Typography>
                  <Typography className={classes.totalPrice}>
                    {
                      parseFloat(calculatePrice())
                        .toFixed(2)
                        ?.replace(/\./, ` ${configuration.currencySymbol} `)
                      /* ?.replace("00", "") */
                    }
                  </Typography>
                </Box>
                <Box mb={theme.spacing(2)}>
                  <Typography className={classes.itemDescription}>
                    {data?.food?.description ?? ""}
                  </Typography>
                </Box>
                <Divider orientation="horizontal" />
                <FormControl style={{ flex: 1, display: "flex" }}>
                  <FormGroup>
                    {selectedVariation?.ingredients?.map(
                      (ingredient, index) => (
                        <>
                          <Box key={`VARIATION_${index}`}>
                            {ingredientCheckboxes(ingredient)}
                          </Box>
                          {/* <Divider
                            orientation="horizontal"
                            style={{ marginBottom: "30px" }}
                          /> */}
                        </>
                      )
                    )}
                  </FormGroup>
                </FormControl>
              </DialogTitle>
              <DialogContent>
                {data?.food.variations.length > 1 && (
                  <>
                    <HeadingView />
                    <FormControl style={{ width: "100%" }}>
                      <RadioGroup>
                        {data?.food.variations.map((variation, index) => (
                          <Box
                            key={`ADDONS_${index}`}
                            display="flex"
                            justifyContent="space-between"
                          >
                            <FormControlLabel
                              checked={variation._id === selectedVariation._id}
                              value={variation._id}
                              onClick={() => onSelectVariation(variation)}
                              control={<Radio color="primary" />}
                              label={
                                <Typography
                                  style={{
                                    color: theme.palette.text.primary,
                                  }}
                                  className={classes.priceTitle}
                                >
                                  {variation.title}
                                </Typography>
                              }
                            />

                            <Typography className={classes.variationPrice}>
                              {
                                variation.price
                                  .toFixed(2)
                                  ?.replace(
                                    /\./,
                                    ` ${configuration.currencySymbol} `
                                  )
                                /* ?.replace("00", "") */
                              }
                            </Typography>
                          </Box>
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <Divider
                      orientation="horizontal"
                      style={{ marginBottom: "30px" }}
                    />
                  </>
                )}
                <Box />
                <FormControl style={{ flex: 1, display: "flex" }}>
                  <FormGroup>
                    {selectedVariation?.addons?.map((addon, index) => (
                      <>
                        <Box key={`VARIATION_${index}`}>
                          <HeadingView
                            title={addon.title}
                            subTitle={addon.description}
                            error={addon.error}
                            status={
                              addon.quantityMinimum === 0
                                ? t("optional")
                                : `${addon.quantityMinimum} ${t("required")}`
                            }
                          />
                          {radioORcheckboxes(addon)}
                        </Box>
                        <Divider
                          orientation="horizontal"
                          style={{ marginBottom: "30px" }}
                        />
                      </>
                    ))}
                  </FormGroup>
                  <FormGroup>
                    <HeadingView
                      title={t("specialInstructions")}
                      subTitle={t("anySpecific")}
                      status={t("optional")}
                      error={false}
                    />
                    <Box mt={theme.spacing(2)} />
                    <OutlinedInput
                      id="special-instructions"
                      name="special-instructions"
                      multiline
                      minRows={2}
                      maxRows={3}
                      value={specialInstructions}
                      onChange={(event) =>
                        setSpecialInstructions(event.target.value)
                      }
                      placeholder={t("placeholder")}
                      // variant="filled"
                      color="secondary"
                      inputProps={{
                        style: {
                          color: theme.palette.grey[200],
                          backgroundColor: theme.palette.primary.lightest,
                        },
                        maxLength: 144,
                      }}
                      size="small"
                    />
                  </FormGroup>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Box
                  style={{ background: "white", width: "100%", height: "75px" }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <IconButton
                    size="small"
                    className={classes.mr}
                    style={{
                      minWidth: "auto",
                      backgroundColor: theme.palette.common.white,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      onRemove();
                    }}
                  >
                    <RemoveIcon fontSize="medium" style={{ color: "white" }} />
                  </IconButton>
                  <Typography
                    style={{
                      fontSize: "1.25rem",
                      border: "1px solid",
                      padding: "5px 15px",
                      borderRadius: 10,
                    }}
                    className={`${classes.mr} ${classes.quantity}`}
                  >
                    {quantity}
                  </Typography>
                  <IconButton
                    size="small"
                    className={classes.mr}
                    style={{
                      minWidth: "auto",
                      backgroundColor: theme.palette.common.white,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      onAdd();
                    }}
                  >
                    <AddIcon fontSize="medium" style={{ color: "white" }} />
                  </IconButton>
                  <Button
                    variant="contained"
                    color="primary"
                    className={clsx(classes.checkoutContainer, {
                      [classes.disableBtn]: !validateButton(),
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      onPressAddToCart();
                    }}
                  >
                    <Typography className={classes.checkoutText}>
                      {t("addToCart")}
                    </Typography>
                  </Button>
                </Box>
              </DialogActions>
            </Box>
          </Paper>
        </Box>
      </Dialog>
    </>
  );
}
export default React.memo(VariationModal);
